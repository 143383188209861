<template>
  <v-container class="diminBg" fluid>
    <v-container>

      <v-card class="pa-4" elevation="4">

        <!-- Header -->
        <v-row class="mb-3">
          <v-col cols="12" align="center">
            <v-card class="titleUnderline py-3" dark min-height="12px" elevation="1">
              <v-img
                alt="Logo UCN"
                class="shrink mb-2"
                contain
                src="@/assets/logoGalpon.png"
                transition="scale-transition"
                width="120"
              />
              <h2>Galpón Cultural</h2>
              <h4 class="text-subtitle-1">Área Artístico Cultural del Departamento Estudiantil - UCN Coquimbo</h4>
            </v-card>
          </v-col>
        </v-row>

        <!-- Video and Who we Are -->
        <v-row>

          <v-col lg="6" md="12">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/V2tWloi9758" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </v-col>

          <v-col lg="6" md="12">
            <v-container class="pa-4">

              <h2>Quienes Somos</h2>

              <v-row class="mt-1">
                <v-col cols="12">
                  <v-card class="titleUnderline" min-height="12px" elevation="1" />
                </v-col>
              </v-row>
            
              <v-row class="pt-1">

                <v-col cols="12">
                  <v-divider class="mb-4"></v-divider>

                  <p class="mb-5">El Galpón Cultural UCN, inaugurado el 4 de Abril de 2004, es un espacio fundado y administrado por el Área Artístico Cultural del Departamento Estudiantil de la Universidad Católica del Norte, fue creado con el propósito de dar un espacio profesional y eficiente a los intereses artístico culturales de los estudiantes UCN, sede Coquimbo.</p>
                  <p class="text-body-2">Las actividades del Galpón Cultural UCN, se enfocan principalmente en entregar a los estudiantes talleres formativos en diversas disciplinas del arte y la cultura, permitiendo descubrir y desarrollar talentos y grupos artísticos, como también brindar actividades interdisciplinarias como Cafés Literarios, Muestras Artísticas, Festival de la Canción Universitaria, Encuentros de Teatro, Encuentros de Danza y Ferias Artesanales Universitarias, entre otros eventos programados anualmente, logrando generar espacios de participación tanto para el estudiantado, como para académicos y personal de apoyo UCN, incluyendo a los habitantes de Guayacán, y de la Región de Coquimbo.</p>
                </v-col>

              </v-row>

            </v-container>
          </v-col>
      
        </v-row>

        <v-divider></v-divider>

        <!-- Team -->
        <v-row>
          <v-col>
            <v-container class="pa-4">
              <h2 class="mb-5">Equipo de Trabajo</h2>

              <v-row>
              
                <v-col v-for="(member, idx) in staff" :key="idx" md="4" sm="12">
                  <v-card
                    :color="(member.position !== 'Ayudante') ? 'indigo lighten-2' : 'blue-grey'"
                    dark
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="text-h6 pb-3">
                          {{member.name}}
                        </v-card-title>

                        <v-card-subtitle>
                          {{member.position}}
                        </v-card-subtitle>

                      </div>
                    </div>
                  </v-card>
                </v-col>

              </v-row>

            </v-container>

          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <!-- History -->
        <v-row>
          <v-col>
            <v-container class="pa-4">
              <h2 class="mb-5">Historia</h2>

              <v-timeline>
                <v-timeline-item
                  v-for="(event, i) in history"
                  :key="i"
                  :color="event.color"
                  small
                >
                  <template v-slot:opposite>
                    <span
                      :class="`headline font-weight-bold ${event.color}--text`"
                      v-text="event.year"
                    ></span>
                  </template>
                  <div class="py-4">
                    <h2 :class="`headline font-weight-light mb-4 ${event.color}--text`">
                      {{event.title}}
                    </h2>
                    <div>
                      {{event.description}}
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>

            </v-container>

          </v-col>
        </v-row>

      </v-card>

    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "WhoWeAre",
  data () {
    return {
      //state
      isLoadingStaff: true,
      //data
      staff: [],
      history: [
        {
          color: 'cyan',
          year: '1993',
          title: 'Inicio Área Artístico Cultural UCN Coquimbo',
          description: '',
        },
        {
          color: 'red',
          year: '1995',
          title: 'Primera Muestra Artística',
          description: '',
        },
        {
          color: 'green',
          year: '2004',
          title: 'Inauguración Galpón Cultural UCN',
          description: '',
        },
        {
          color: 'pink',
          year: '2019',
          title: 'LABQUI',
          description: 'Laboratorio de Innovación y Emprendimiento Cultural Inclusivo, proyecto financiado por el Ministerio de las Culturas, las Artes y el Patrimonio, FONDART Regional 2019.',
        },
        {
          color: 'amber',
          year: '2019',
          title: 'Cultura en Acción para Todas y Todos',
          description: 'Proyecto financiado por el Servicio Nacional de la Discapacidad FONAPI, 2019.',
        },
        {
          color: 'orange',
          year: '2021-2022',
          title: 'CADEG',
          description: 'Encuentro de Danza y Teatro desde la Diversidad y Equidad de Género, proyecto financiado por el Ministerio de Educación, Fondo de Desarrollo Institucional, línea Emprendimiento Estudiantil 2021.',
        },
      ],
    }
  },
  methods: {
    async loadStaff() {
      try {

        this.isLoadingStaff = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/staff`)
        this.staff = response.data.data

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingStaff = false
      }
    },
  },
  mounted () {
    this.loadStaff()
  },
}
</script>

<style lang="scss" scoped>

.diminBg {
  background-color: #ededed;
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, #2980b9 25%, #2c3e50 10%);
  border-radius: 7px 7px 7px 7px;
}

</style>
